import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { ContentPlaceholder, Emoji } from '@capturi/ui-components'
import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const NoAccessibleTrackers: React.FC = () => {
  return (
    <ContentPlaceholder.Container mt="10vh">
      <ContentPlaceholder.Image as={Icon_EmptyState} />
      <ContentPlaceholder.Heading fontSize="2xl">
        <Text fontSize="4xl">
          <Trans>
            Hot damn! <Emoji symbol="🧯" fontSize="4xl" />
          </Trans>
        </Text>
      </ContentPlaceholder.Heading>
      <ContentPlaceholder.Body>
        <Text mt={4}>
          <Trans>You don’t have view rights for one or more filters.</Trans>
        </Text>
        <Text mt={4}>
          <Trans>
            Remove or change the{' '}
            <Text as="span" color="warning">
              filters
            </Text>{' '}
            in order to see the results.
          </Trans>
        </Text>
      </ContentPlaceholder.Body>
    </ContentPlaceholder.Container>
  )
}

export default NoAccessibleTrackers
